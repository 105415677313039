import {motion} from "framer-motion";
import {useState} from "react";
import {RevealAnimation} from "../../animations/RevealAnimation";

export const TechnologySection = () => {
    return (
        <div
            id="technologies"
            className="w-screen px-8 py-12 max-w-6xl mx-auto">
            <RevealAnimation>
                <h3 className="text-4xl mb-4">Technologies</h3>
                <ChipTabs />
            </RevealAnimation>
        </div>
    );
};

type TabKeys = "Languages" | "Front-End" | "Back-End" | "AI/ML" | "Software";

const tabs: TabKeys[] = ["Languages", "Front-End", "Back-End", "AI/ML", "Software"];

const LanguagesKnown: string[] = ["TypeScript", "JavaScript", "HTML", "CSS", "Python", "C#", "Rust", "SQL", "Bash/Shell"];

const LanguagesContent = (
    <div className="grid   grid-cols-4 gap-1 ">
        {LanguagesKnown.map((language, index) => (
            <motion.div
                initial={{opacity: 0, y: -40, rotate: -10}}
                animate={{opacity: 1, y: 0, rotate: 0}}
                transition={{duration: Math.min(index * 0.05 + 0.1, 1)}}
                key={index}>
                <p>{language}</p>
            </motion.div>
        ))}
    </div>
);
const FrontEndKnown: string[] = ["NextJS", "React", "React Native", "Solid", "Redux", "Zustand", "Electron", "Tauri", "Context API", "Jest", "Cypress", "Framer Motion", "CSS/SCSS Modules", "Styled Components", "Tailwind", "Sass", "Bootstrap", "D3", "Stripe"];

const FrontEndContent = (
    <div className="grid  grid-cols-4 gap-1 ">
        {FrontEndKnown.map((frontEnd, index) => (
            <motion.div
                initial={{opacity: 0, y: -40, rotate: -10}}
                animate={{opacity: 1, y: 0, rotate: 0}}
                transition={{duration: Math.min(index * 0.05 + 0.1, 1)}}
                key={frontEnd}>
                <p>{frontEnd}</p>
            </motion.div>
        ))}
    </div>
);
const BackEndKnown: string[] = ["Node", "MongoDB", "Firebase", "Redis", "Express", "GraphQL", "Parse", "MySQL", "Postgres", "JWT"];

const BackEndContent = (
    <div className="grid  grid-cols-4 gap-1 ">
        {BackEndKnown.map((backEnd, index) => (
            <motion.div
                initial={{opacity: 0, y: -40, rotate: -10}}
                animate={{opacity: 1, y: 0, rotate: 0}}
                transition={{duration: Math.min(index * 0.05 + 0.1, 1)}}
                key={backEnd}>
                <p>{backEnd}</p>
            </motion.div>
        ))}
    </div>
);
const AIMLKnown: string[] = ["Pandas", "Scikit-Learn", "Tensorflow 3", "MatPlotLib", "GPT Models", "Image Models", "Audio Models", "Fine-tuning Models", "Embedded Models"];

const AIMLContent = (
    <div className="grid  grid-cols-4 gap-1 ">
        {AIMLKnown.map((AIML, index) => (
            <motion.div
                initial={{opacity: 0, y: -40, rotate: -10}}
                animate={{opacity: 1, y: 0, rotate: 0}}
                transition={{duration: Math.min(index * 0.05 + 0.1, 1)}}
                key={AIML}>
                <p>{AIML}</p>
            </motion.div>
        ))}
    </div>
);
const SoftwareKnown: string[] = ["Figma", "Trello", "Shortcut", "Jira", "Github", "Github Actions"];

const SoftwareContent = (
    <div className="grid  grid-cols-4 gap-1 ">
        {SoftwareKnown.map((software, index) => (
            <motion.div
                initial={{opacity: 0, y: -40, rotate: -10}}
                animate={{opacity: 1, y: 0, rotate: 0}}
                transition={{duration: Math.min(index * 0.05 + 0.1, 1)}}
                key={software}>
                <p>{software}</p>
            </motion.div>
        ))}
    </div>
);

const tabContents: Record<TabKeys, JSX.Element> = {
    Languages: LanguagesContent,
    "Front-End": FrontEndContent,
    "Back-End": BackEndContent,
    "AI/ML": AIMLContent,
    Software: SoftwareContent,
};

const ChipTabs = () => {
    const [selected, setSelected] = useState(tabs[0]);

    return (
        <div className="select-none w-[375px] md:w-[800px] h-[400px] md:h-[200px]">
            <div className="flex flex-wrap gap-2 ">
                {tabs.map((tab) => (
                    <Chip
                        text={tab}
                        selected={selected === tab}
                        setSelected={setSelected}
                        key={tab}
                    />
                ))}
            </div>
            <div className="tab-content mt-4">{tabContents[selected]}</div>
        </div>
    );
};

const Chip: React.FC<{text: string; selected: any; setSelected: any}> = ({text, selected, setSelected}) => {
    function handleOnSelect() {
        setSelected(text);
    }
    return (
        <button
            onClick={handleOnSelect}
            className={`${selected ? "text-white" : "text-slate-300 hover:text-slate-200 hover:bg-slate-700"} text-sm transition-colors px-3 py-1.5 rounded-md relative`}>
            <span className="relative z-10">{text}</span>
            {selected && (
                <motion.span
                    layoutId="pill-tab"
                    transition={{type: "spring", duration: 0.5}}
                    className="absolute inset-0 z-0 bg-gradient-to-r from-violet-600 to-indigo-600 rounded-md"></motion.span>
            )}
        </button>
    );
};
