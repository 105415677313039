import {motion} from "framer-motion";
import React from "react";

const draw = {
    hidden: {pathLength: 0, opacity: 0},
    visible: (i: any) => {
        const delay = 1 + i * 0.5;
        return {
            pathLength: 1,
            opacity: 1,
            transition: {
                pathLength: {delay, type: "spring", duration: 7.5, bounce: 0},
                opacity: {delay, duration: 0.01},
            },
        };
    },
};

export const GithubIcon = () => {
    return (
        <a
            href="https://github.com/JarrodSavard"
            target="_blank"
            rel="noopener noreferrer"
        >
            <motion.svg
                initial="hidden"
                animate="visible"
                stroke="currentColor"
                fill="none"
                stroke-width="2"
                viewBox="0 0 24 24"
                stroke-linecap="round"
                stroke-linejoin="round"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/motion."
            >
                <motion.path
                    variants={draw}
                    d="M9 19c-5 1.5-5-2.5-7-3m14 6v-3.87a3.37 3.37 0 0 0-.94-2.61c3.14-.35 6.44-1.54 6.44-7A5.44 5.44 0 0 0 20 4.77 5.07 5.07 0 0 0 19.91 1S18.73.65 16 2.48a13.38 13.38 0 0 0-7 0C6.27.65 5.09 1 5.09 1A5.07 5.07 0 0 0 5 4.77a5.44 5.44 0 0 0-1.5 3.78c0 5.42 3.3 6.61 6.44 7A3.37 3.37 0 0 0 9 18.13V22"
                />
            </motion.svg>
        </a>
    );
};

export const LinkedInIcon = () => {
    return (
        <a
            href="https://www.linkedin.com/in/jarrod-savard/"
            target="_blank"
            rel="noopener noreferrer"
        >
            <motion.svg
                initial="hidden"
                animate="visible"
                stroke="currentColor"
                fill="none"
                stroke-width="2"
                viewBox="0 0 24 24"
                stroke-linecap="round"
                stroke-linejoin="round"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
            >
                <motion.path
                    variants={draw}
                    d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z"
                ></motion.path>
                <motion.rect
                    variants={draw}
                    width="4"
                    height="12"
                    x="2"
                    y="9"
                ></motion.rect>
                <motion.circle
                    variants={draw}
                    cx="4"
                    cy="4"
                    r="2"
                ></motion.circle>
            </motion.svg>
        </a>
    );
};

export const LogoIcon = () => {};
