import Plausible from "plausible-tracker";
import "./App.scss";
import {AboutSection} from "./components/AboutSection";
// import {ContactSection} from "./components/ContactSection";
// import {ExperienceSection} from "./components/ExperienceSection";
import {useEffect} from "react";
import {Footer} from "./components/Footer";
import {HeroSection} from "./components/HeroSection";
import {Navbar} from "./components/Navbar";
import {ProjectsSection} from "./components/ProjectsSection";
import {TechnologySection} from "./components/TechnologiesSection";

function App() {
    const plausible = Plausible({
        domain: "jarrodsavard.com",
        trackLocalhost: false,
    });

    useEffect(() => {
        plausible.enableAutoPageviews();
    }, []);
    return (
        <div className="App overflow-x-hidden">
            <Navbar />
            <main>
                <HeroSection />
                <AboutSection />
                <ProjectsSection />
                <TechnologySection />
                {/* <ExperienceSection /> */}
                <Footer />
            </main>
        </div>
    );
}

export default App;
