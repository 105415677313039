import {motion} from "framer-motion";
import {useState} from "react";
import {GithubIcon, LinkedInIcon} from "../../images/svgs/Icons";
import {Modal} from "../Modal";
import styles from "./styles.module.scss";

export const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <motion.nav
            initial={{opacity: 0, y: -50}}
            animate={{opacity: 1, y: 0}}
            exit={{opacity: 0}}
            transition={{duration: 1}}
            className={styles.wrapper}>
            <Modal
                isOpen={isOpen}
                setIsOpen={setIsOpen}
            />
            <p className={styles.logo}>Jarrod Savard</p>
            <motion.div
                initial="initial"
                animate="animate"
                exit={{opacity: 0}}
                className={styles.links}
                variants={{
                    initial: {opacity: 0},
                    animate: {opacity: 1, transition: {ease: "easeInOut", staggerChildren: 0.35, delayChildren: 1.25}},
                }}>
                <motion.a
                    href="#about"
                    variants={{initial: {opacity: 0}, animate: {opacity: 1}}}>
                    About
                </motion.a>
                <motion.a
                    href="#projects"
                    variants={{initial: {opacity: 0}, animate: {opacity: 1}}}>
                    Projects
                </motion.a>
                <motion.a
                    href="#technologies"
                    variants={{initial: {opacity: 0}, animate: {opacity: 1}}}>
                    Technologies
                </motion.a>
                <motion.a
                    href="#"
                    variants={{initial: {opacity: 0}, animate: {opacity: 1}}}
                    onClick={() => setIsOpen(true)}>
                    Contact
                </motion.a>
                {/* <motion.a
                    href="#experience"
                    variants={{initial: {opacity: 0}, animate: {opacity: 1}}}
                >
                    Experience
                </motion.a> */}
            </motion.div>
            <div className={styles.socials}>
                <GithubIcon />
                <LinkedInIcon />
            </div>
        </motion.nav>
    );
};
