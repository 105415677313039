import {RevealAnimation} from "../../animations/RevealAnimation";
import {ProjectsCard} from "../ProjectsCard";

interface IProjectProps {
    title: string;
    description: string;
    image: string;
    codeLink: string;
    liveLink: string;
    softwaresUsed: string[];
    codeText: string;
}

const Projects: IProjectProps[] = [
    {
        title: "Market Caviar",
        description: "SaaS business focused on empowering traders with the necessary tools to make informed decisions in the equities and ETF market. Market Caviar aims to enhance trading strategies and provide valuable insights for its users.",
        image: "/images/marketcaviar.png",
        codeLink: "https://github.com/JarrodSavard",
        liveLink: "https://www.marketcaviar.com/",
        softwaresUsed: ["NextJS"],
        codeText: "Private",
    },
    {
        title: "Bool Bots",
        description: "Not only fun, but also an aid in helping to understand basic Boolean logic. This game has an arena of 8x8 game tiles in which your bots move. The Bots are assigned boolean values of 0 or 1 and boolean operations - AND, OR, NOR, XOR.",
        image: "/images/boolbots.png",
        codeLink: "https://github.com/chingu-voyages/v44-tier2-team-20",
        liveLink: "https://chingu-voyages.github.io/v44-tier2-team-20/",
        softwaresUsed: ["ReactJS"],
        codeText: "Code",
    },
    {
        title: "Weather Search",
        description: "Uses the OpenWeatherMap API to generate responses. Includes search debounce to prevent unnecessary API requests, recommendations based on the user's search history, and displays relevant weather information for 5 days.",
        image: "/images/weatherApplication.png",
        codeLink: "https://github.com/JarrodSavard/open-weather-api-site",
        liveLink: "https://open-weather-api-site.vercel.app/",
        softwaresUsed: ["ReactJS"],
        codeText: "Code",
    },
];

export const ProjectsSection = () => {
    return (
        <div
            id="projects"
            className="w-full px-8 md:px-8 py-12  items-center gap-8 max-w-6xl mx-auto ">
            <RevealAnimation>
                <h1 className="text-4xl mb-4">Projects</h1>
                <div className="flex gap-10 flex-wrap justify-center xl:flex-nowrap ">
                    {Projects.map((project) => (
                        <ProjectsCard
                            title={project.title}
                            description={project.description}
                            image={project.image}
                            codeLink={project.codeLink}
                            liveLink={project.liveLink}
                            softwaresUsed={project.softwaresUsed}
                            codeText={project.codeText}
                        />
                    ))}
                </div>
            </RevealAnimation>
        </div>
    );
};
