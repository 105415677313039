import {motion} from "framer-motion";
import React from "react";
import {Button} from "../Button";

interface IProjectCardProps {
    title: string;
    description: string;
    image: string;
    codeLink: string;
    liveLink: string;
    softwaresUsed: string[];
    codeText: string;
}

export const ProjectsCard = (props: IProjectCardProps) => {
    return (
        <div className="select-none group w-[348px] h-[458px] relative mx-auto  max-w-sm overflow-hidden rounded-lg bg-[##030202] p-1 transition-all duration-500 hover:bg-slate-800/50">
            <motion.div className="  bg-gray-900  h-[450px] w-[340px] rounded-md">
                <motion.img
                    src={props.image}
                    className="absolute w-[340px] h-[220px]  object-fill rounded-t-md"
                />
                <div className="relative top-1/2 h-1/2 z-20 px-3 py-1.5 ">
                    <div className="w-full flex justify-between mb-2">
                        <h1 className="text-2xl">{props.title}</h1>
                        <div className="flex gap-3 pr-3">
                            {props.softwaresUsed.map((software) => (
                                <p className="text-green-400 font-semibold">{software}</p>
                            ))}
                        </div>
                    </div>
                    <p className="text-white text-sm ">{props.description}</p>
                    <div className="absolute bottom-5 flex w-[95%] justify-evenly">
                        <Button
                            text={props.codeText}
                            live={{link: props.codeLink}}
                        />
                        <Button
                            text="Live"
                            live={{link: props.liveLink}}
                        />
                    </div>
                </div>
            </motion.div>
            <motion.div
                initial={{rotate: "0deg"}}
                animate={{rotate: "360deg"}}
                style={{scale: 1.75}}
                transition={{
                    repeat: Infinity,
                    duration: 3.5,
                    ease: "linear",
                }}
                className="absolute inset-0 -z-10 bg-gradient-to-br from-violet-500 via-[#030202] to-indigo-200 opacity-0 transition-opacity duration-500 group-hover:opacity-100"
            />
        </div>
    );
};
