import {motion, useAnimation, useInView} from "framer-motion";
import React, {useEffect, useRef} from "react";
import styles from "./styles.module.scss";

export enum WidthEnum {
    FitContent = "fit-content",
    FillParent = "100%",
}

interface IRevealAnimationProps {
    children: React.ReactNode;
    width?: WidthEnum;
    reverse?: boolean;
}

export const RevealAnimation = (props: IRevealAnimationProps) => {
    const ref = useRef(null);
    const isInView = useInView(ref, {once: true});
    const mainControls = useAnimation();
    const slideControls = useAnimation();

    useEffect(() => {
        if (isInView) {
            mainControls.start("visible");
            slideControls.start("visible");
        }
    }, [isInView, mainControls, slideControls]);

    return (
        <div
            ref={ref}
            style={{width: props.width || WidthEnum.FitContent}}
            className={styles.wrapper}>
            {!props.reverse ? (
                <motion.div
                    variants={{
                        hidden: {opacity: 0, y: 75},
                        visible: {opacity: 1, y: 0},
                    }}
                    initial="hidden"
                    animate={mainControls}
                    transition={{duration: 0.75}}>
                    {props.children}
                </motion.div>
            ) : (
                <motion.div
                    variants={{
                        hidden: {opacity: 0, y: -75},
                        visible: {opacity: 1, y: 0},
                    }}
                    initial="hidden"
                    animate={mainControls}
                    transition={{duration: 0.75}}>
                    {props.children}
                </motion.div>
            )}

            {/* <motion.div
                variants={{
                    hidden: {left: 0},
                    visible: {left: "100%"},
                }}
                initial="hidden"
                animate={slideControls}
                transition={{duration: 0.5, ease: "easeIn"}}
                className={styles.cover}
            ></motion.div> */}
        </div>
    );
};
