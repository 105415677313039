import React, {useState} from "react";
import {GithubIcon, LinkedInIcon} from "../../images/svgs/Icons";
import {Modal} from "../Modal";

export const Footer = () => {
    const [isOpen, setIsOpen] = useState(false);
    return (
        <div
            id="footer"
            className="px-8 py-12 max-w-6xl mx-auto grid grid-cols-2 items-center">
            <div>
                {/* Contact Information */}
                {/* <input
                        type="email"
                        className="text-gray-600 hover:text-gray-900"
                    >
                        Jarrodsavard2x180@gmail.com
                    </input> */}
                <p
                    className="text-gray-400 hover:text-gray-100 cursor-pointer"
                    onClick={() => {
                        setIsOpen(true);
                    }}>
                    Jarrodsavard2x180@gmail.com
                </p>
                <Modal
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                />
                <p
                    className="text-gray-400 hover:text-gray-100 cursor-pointer"
                    onClick={() => {
                        setIsOpen(true);
                    }}>
                    +1 (310) 953-7628
                </p>
            </div>
            <div className="flex gap-5">
                {/* Socials Information */}
                <GithubIcon />
                <LinkedInIcon />
            </div>
        </div>
    );
};
