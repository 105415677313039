import {motion, useScroll} from "framer-motion";
import {useEffect, useRef, useState} from "react";
import {RevealAnimation} from "../../animations/RevealAnimation";
import {Button} from "../Button";
import {Modal} from "../Modal";

export const HeroSection = () => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <section className="select-none w-full px-8 py-12 grid grid-cols-1 md:grid-cols-2 items-center gap-8 max-w-6xl mx-auto">
            <Modal
                isOpen={isOpen}
                setIsOpen={setIsOpen}
            />
            <div>
                <RevealAnimation>
                    <h3 className="text-4xl md:text-6xl font-semibold">
                        Hello, I'm <br />
                        Jarrod Savard.
                    </h3>
                    <p className="text-base md:text-lg text-slate-200 my-4 md:my-6">I'm a Fullstack Developer with a Meta Front-End Professional Developer Certification. I invite you to explore and delve into the various projects I've crafted, as well as read a little bit about me to get to know me better! </p>
                </RevealAnimation>
                <div className="flex w-full justify-between">
                    <Button
                        onClick={() => setIsOpen(true)}
                        text="Contact"
                    />
                </div>
            </div>
            <ShuffleGrid />
        </section>
    );
};

const shuffle = (array: any[]) => {
    let currentIndex = array.length,
        randomIndex;

    while (currentIndex !== 0) {
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
    }

    return array;
};

const squareData = [
    {
        id: 1,
        src: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/typescript/typescript-original.svg",
        link: "https://www.typescriptlang.org/",
    },
    {
        id: 2,
        src: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/python/python-original.svg",
        link: "https://www.python.org/",
    },
    {
        id: 3,
        src: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/nodejs/nodejs-original.svg",
        link: "https://www.nodejs.org/",
    },
    {
        id: 4,
        src: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/react/react-original.svg",
        link: "https://reactjs.org/",
    },
    {
        id: 5,
        src: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/linux/linux-original.svg",
        link: "https://www.linux.org/",
    },
    {
        id: 6,
        src: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/figma/figma-original.svg",
        link: "https://www.figma.com/",
    },
    {
        id: 7,
        src: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/jira/jira-original.svg",
        link: "https://www.atlassian.com/",
    },
    {
        id: 8,
        src: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/d3js/d3js-original.svg",
        link: "https://d3js.org/",
    },
    {
        id: 9,
        src: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/mongodb/mongodb-plain-wordmark.svg",
        link: "https://www.mongodb.com/",
    },
    {
        id: 10,
        src: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/firebase/firebase-plain-wordmark.svg",
        link: "https://firebase.google.com/",
    },
    {
        id: 11,
        src: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/redis/redis-original.svg",
        link: "https://redis.io/",
    },
    {
        id: 12,
        src: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/jest/jest-plain.svg",
        link: "https://jestjs.io/",
    },
    {
        id: 13,
        src: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/tensorflow/tensorflow-original.svg",
        link: "https://www.tensorflow.org/",
    },
    {
        id: 14,
        src: "https://img.icons8.com/glyph-neue/128/FFFFFF/github.png",
        link: "https://github.com/JarrodSavard",
    },
    {
        id: 15,
        src: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/tailwindcss/tailwindcss-plain.svg",
        link: "https://tailwindcss.com/",
    },
    {
        id: 16,
        src: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/ubuntu/ubuntu-plain-wordmark.svg",
        link: "https://ubuntu.com/",
    },
];

const generateSquares = () => {
    return shuffle(squareData).map((sq) => (
        <motion.a
            key={sq.id}
            layout
            transition={{duration: 1.5, type: "spring"}}
            className="w-full h-full"
            style={{
                backgroundImage: `url(${sq.src})`,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                width: "90%",
                height: "90%",
            }}
            href={sq.link}
            rel="noopener noreferrer"
            target="_blank"
        />
    ));
};

const ShuffleGrid = () => {
    const timeoutRef = useRef<number | null>(null);
    const [squares, setSquares] = useState(generateSquares());
    const {scrollY} = useScroll();
    const firstRender = useRef(true);
    useEffect(() => {
        if (scrollY.get() < 350) {
            if (!firstRender.current) {
                shuffleSquares();
            } else {
                timeoutRef.current = setTimeout(shuffleSquares, 5000) as unknown as number;
            }
            return () => {
                if (timeoutRef.current !== null) {
                    clearTimeout(timeoutRef.current);
                }
            };
        }

        firstRender.current = false;
    }, []);

    const shuffleSquares = () => {
        if (scrollY.get() < 350) {
            setSquares(generateSquares());
        }

        timeoutRef.current = setTimeout(shuffleSquares, 5000) as unknown as number;
    };

    return <div className="grid grid-cols-4 grid-rows-4 h-[450px] gap-1">{squares.map((sq) => sq)}</div>;
};
