import {addDoc, collection} from "@firebase/firestore";
import {motion} from "framer-motion";
import {FC, Fragment, useEffect, useRef, useState} from "react";
import {FiCheckCircle} from "react-icons/fi";
import {db} from "../../firebase-config";

interface Question {
    key: string;
    text: string;
    postfix?: string;
    complete: boolean;
    value: string;
}

interface TerminalBodyProps {
    containerRef: React.RefObject<HTMLDivElement>;
    inputRef: React.RefObject<HTMLInputElement>;
}

interface PreviousQuestionsProps {
    questions: Question[];
}

interface CurrentQuestionProps {
    curQuestion: Question | undefined;
}

interface SummaryProps {
    questions: Question[];
    setQuestions: React.Dispatch<React.SetStateAction<Question[]>>;
}

interface CurLineProps extends TerminalBodyProps {
    text: string;
    focused: boolean;
    setText: React.Dispatch<React.SetStateAction<string>>;
    setFocused: React.Dispatch<React.SetStateAction<boolean>>;
    command: string;
    handleSubmitLine: (value: string) => void;
}

const QUESTIONS = [
    {
        key: "email",
        text: "To start, could you give me ",
        postfix: "your email?",
        complete: false,
        value: "",
    },
    {
        key: "name",
        text: "Awesome! And what's ",
        postfix: "your name?",
        complete: false,
        value: "",
    },
    {
        key: "description",
        text: "Perfect, and ",
        postfix: "how can I help you?",
        complete: false,
        value: "",
    },
];

const submissionCollectionRef = collection(db, "submission");
export const ContactForm: FC = () => {
    const containerRef = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);

    return (
        <section
            id="contact"
            className="w-full ">
            <div
                ref={containerRef}
                onClick={() => {
                    inputRef.current?.focus();
                }}
                className={"h-96 bg-slate-950/70 backdrop-blur rounded-lg w-full max-w-3xl mx-auto overflow-y-scroll shadow-xl cursor-text font-mono"}>
                <TerminalHeader />
                <TerminalBody
                    inputRef={inputRef}
                    containerRef={containerRef}
                />
            </div>
        </section>
    );
};

const TerminalHeader: FC = () => {
    return (
        <div className="w-full p-3 bg-slate-900 flex items-center gap-1 sticky top-0">
            <div className="w-3 h-3 rounded-full bg-red-500" />
            <div className="w-3 h-3 rounded-full bg-yellow-500" />
            <div className="w-3 h-3 rounded-full bg-green-500" />
            <span className="text-sm text-green-500 font-semibold absolute left-[50%] -translate-x-[50%]">contact@JarrodSavard.com</span>
        </div>
    );
};

const TerminalBody: FC<TerminalBodyProps> = ({containerRef, inputRef}) => {
    const [focused, setFocused] = useState(false);
    const [text, setText] = useState("");

    const [questions, setQuestions] = useState<Question[]>(QUESTIONS);

    const curQuestion = questions.find((q) => !q.complete);

    const handleSubmitLine = (value: string) => {
        if (curQuestion) {
            setQuestions((pv) =>
                pv.map((q) => {
                    if (q.key === curQuestion.key) {
                        return {
                            ...q,
                            complete: true,
                            value,
                        };
                    }
                    return q;
                })
            );
        }
    };

    return (
        <div className="p-2 text-green-500 text-lg ">
            <InitialText />
            <PreviousQuestions questions={questions} />
            <CurrentQuestion curQuestion={curQuestion} />
            {curQuestion ? (
                <CurLine
                    text={text}
                    focused={focused}
                    setText={setText}
                    setFocused={setFocused}
                    inputRef={inputRef}
                    command={curQuestion?.key || ""}
                    handleSubmitLine={handleSubmitLine}
                    containerRef={containerRef}
                />
            ) : (
                <Summary
                    questions={questions}
                    setQuestions={setQuestions}
                />
            )}
        </div>
    );
};

const InitialText: FC = () => {
    return (
        <>
            <p>Hey there! I'm excited to get in contact 🔗</p>
            <p className="whitespace-nowrap overflow-hidden font-light ">------------------------------------------------------------------------</p>
        </>
    );
};

const PreviousQuestions: FC<PreviousQuestionsProps> = ({questions}) => {
    return (
        <>
            {questions.map((q, i) => {
                if (q.complete) {
                    return (
                        <Fragment key={i}>
                            <p>
                                {q.text || ""}
                                {q.postfix && <span className="text-pink-500">{q.postfix}</span>}
                            </p>
                            <p className="text-pink-500">
                                <FiCheckCircle className="inline-block mr-2" />
                                <span>{q.value}</span>
                            </p>
                        </Fragment>
                    );
                }
                return <Fragment key={i}></Fragment>;
            })}
        </>
    );
};

const CurrentQuestion: FC<CurrentQuestionProps> = ({curQuestion}) => {
    if (!curQuestion) return <></>;

    return (
        <p>
            {curQuestion.text || ""}
            {curQuestion.postfix && <span className="text-pink-300">{curQuestion.postfix}</span>}
        </p>
    );
};

const Summary: FC<SummaryProps> = ({questions, setQuestions}) => {
    const [complete, setComplete] = useState(false);

    const handleReset = () => {
        setQuestions((pv) => pv.map((q) => ({...q, value: "", complete: false})));
    };

    const handleSend = async () => {
        const formData = questions.reduce((acc, val) => {
            return {...acc, [val.key]: val.value};
        }, {});
        addDoc(submissionCollectionRef, formData);

        setComplete(true);
    };

    return (
        <>
            <p>Beautiful! Here's what we've got:</p>
            {questions.map((q) => {
                return (
                    <p key={q.key}>
                        <span className="text-blue-400">{q.key}:</span> {q.value}
                    </p>
                );
            })}
            <p>Look good?</p>
            {complete ? (
                <p className="text-purple-500">
                    <FiCheckCircle className="inline-block mr-2" />
                    <span>Sent! I'll get back to you ASAP 😎</span>
                </p>
            ) : (
                <div className="flex gap-2 mt-2">
                    <button
                        onClick={handleReset}
                        className="px-3 py-1 text-base hover:opacity-90 transition-opacity rounded bg-slate-100 text-black">
                        Restart
                    </button>
                    <button
                        onClick={handleSend}
                        className="px-3 py-1 text-base hover:opacity-90 transition-opacity rounded bg-slate-100 text-black">
                        Send
                    </button>
                </div>
            )}
        </>
    );
};

const CurLine: FC<CurLineProps> = ({text, focused, setText, setFocused, inputRef, command, handleSubmitLine, containerRef}) => {
    useEffect(() => {
        const handleClickOutside = (event: any) => {
            if (containerRef.current && !containerRef.current.contains(event.target)) {
                setFocused(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [containerRef, setFocused]);

    return (
        <div className="flex items-center">
            <p className="text-pink-300">{command}</p>
            <motion.input
                initial={{width: 0}}
                animate={{width: "auto"}}
                transition={{duration: 0.5}}
                value={text}
                ref={inputRef}
                onChange={(e) => setText(e.target.value)}
                onBlur={() => setFocused(false)}
                onFocus={() => setFocused(true)}
                onKeyPress={(e) => {
                    if (e.key === "Enter") {
                        handleSubmitLine(text);
                        setText("");
                    }
                }}
                className="bg-transparent border-b border-green-500 text-green-500 ml-2 outline-none"
            />
            <span className={`ml-1 ${focused ? "text-green-500" : "text-white"}`}>|</span>
        </div>
    );
};
