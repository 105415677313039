import React from "react";
import {RevealAnimation} from "../../animations/RevealAnimation";

export const AboutSection = () => {
    return (
        <div
            id="about"
            className=" w-full px-8 py-12 max-w-6xl mx-auto">
            <RevealAnimation>
                <h1 className="text-4xl mb-2">About Me</h1>
                <p className="text-lg select-none">I’m a highly motivated and adaptable software developer with a strong background in Agile/Scrum methodologies. I have effectively utilized Jira and Trello for efficient project management, ensuring seamless collaboration and timely delivery. I also have hands-on experience in implementing CI/CD pipelines using GitHub Actions and Jenkins to streamline development processes and enhance code quality. With proficiency in JavaScript, TypeScript, and Python, I am always eager to learn and stay updated with the latest technologies and trends. I am actively seeking a challenging role that fosters growth, collaboration, and innovation, where I can contribute my expertise to create impactful software solutions.</p>
            </RevealAnimation>
        </div>
    );
};
