import {useEffect, useRef} from "react";
import styles from "./styles.module.scss";

interface IButtonProps {
    text: string;

    live?: {
        link?: string;
        scroll?: boolean;
    };
    onClick?: () => void;
}
export const Button = (props: IButtonProps) => {
    const parentRef = useRef<HTMLDivElement | null>(null);
    const btnRef = useRef<HTMLButtonElement | null>(null);

    useEffect(() => {
        if (btnRef.current && parentRef.current) {
            btnRef.current.addEventListener("mouseover", () => {
                parentRef.current!.style.setProperty("--size", "250px");
                parentRef.current!.style.setProperty("--shineColor", "rgba(255, 255, 255, 0.3)");
            });

            btnRef.current.addEventListener("mouseleave", () => {
                parentRef.current!.style.setProperty("--size", "0px");
                parentRef.current!.style.setProperty("--shineColor", "rgba(255, 255, 255, 0.0)");
            });

            btnRef.current.addEventListener("mousemove", (e) => {
                parentRef.current!.style.setProperty("--x", e.offsetX + "px");
                parentRef.current!.style.setProperty("--y", e.offsetY + "px");
            });
        }
    }, []);

    const handleClick = () => {
        if (props.onClick) props?.onClick();
        const target = document.querySelector(`#${props.text.toLowerCase()}`);
        if (target) {
            target.scrollIntoView({behavior: "smooth"});
        }
    };

    return (
        <div
            ref={parentRef}
            className={styles.skeuParent}
        >
            {props.live ? (
                <a
                    className={`overflow-hidden tracking-wider cursor-pointer text-white rounded px-4 py-2 bg-[radial-gradient(100%_100%_at_100%_0%,_#af8bee_0%,_#6903f6_100%)] transition-[box-shadow_0.15s_ease,_transform_0.15s_ease] shadow-md hover:shadow-lg hover:-translate-y-0.5 active:translate-y-0.5 active:shadow-[inset_0px_3px_7px_#6903f6] ${styles.skeu}`}
                    href={props.live.link}
                    target={!props.live.scroll ? "_blank" : ""}
                    rel="noreferrer noopener"
                >
                    {props.text}
                </a>
            ) : (
                <button
                    ref={btnRef}
                    className={`overflow-hidden tracking-wider cursor-pointer text-white rounded px-4 py-2 bg-[radial-gradient(100%_100%_at_100%_0%,_#af8bee_0%,_#6903f6_100%)] transition-[box-shadow_0.15s_ease,_transform_0.15s_ease] shadow-md hover:shadow-lg hover:-translate-y-0.5 active:translate-y-0.5 active:shadow-[inset_0px_3px_7px_#6903f6] ${styles.skeu}`}
                    onClick={handleClick}
                >
                    {props.text}
                </button>
            )}
        </div>
    );
};
